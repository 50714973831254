import ScarsTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-appearance/scars/ScarsTemplate';

import { ACNE_SCAR_APPEARANCE, BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/SkinAppearance/Scars/ScarsTemplate',
  component: ScarsTemplate
};

const createStory = props => () => ({
  components: { ScarsTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><scars-template v-bind="$options.storyProps" /></div>'
});

export const required = createStory({
  acneScars: BOOLEAN.NO
});

export const aiSelected = createStory({
  acneScars: BOOLEAN.YES,
  acneScarsAiSelected: BOOLEAN.YES,
  faceScarsAppearance: ACNE_SCAR_APPEARANCE.RAISED,
  faceScarsAppearanceVisible: true
});

export const all = createStory({
  acneScars: BOOLEAN.YES,
  faceScarsAppearance: ACNE_SCAR_APPEARANCE.RAISED,
  faceScarsAppearanceVisible: true
});
